import React from 'react'
import styled from 'styled-components';
import { useDarkModeManager } from '../../state/user/hooks';
import Logo from '../../assets/svg/logo_light.png';
import LogoDark from '../../assets/svg/logo.png';

const AsFooter = styled.div`
    padding: 12px;
    margin-top: 4em;
`;
const MuiGridContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: -16px;
    width: calc(100% + 16px);
    margin-left: -16px;
`;
const MuiGridGridLg4 = styled.div`
    padding-left: 16px;
    padding-top: 16px;
    flex-basis: 33.333333%;
    flex-grow: 0;
    max-width: 33.333333%;
    ${({ theme }) => theme.mediaWidth.upToXLarge`
        flex-basis: 100%;
        max-width: 100%;
    `};
`;
const MuiGridGridLg2 = styled.div`
    padding-left: 16px;
    padding-top: 16px;
    flex-basis: 16.666667%;
    flex-grow: 0;
    max-width: 16.666667%;
    ${({ theme }) => theme.mediaWidth.upToXLarge`
        flex-basis: 25%;
        max-width: 25%;
    `};
    ${({ theme }) => theme.mediaWidth.upToLarge`
        flex-basis: 50%;
        max-width: 50%;
    `};
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-basis: 100%;
        max-width: 100%;
    `};
`;
const CxPadBox = styled.div`
    background: ${({ theme }) => theme.bg1};
    border-radius: 15px;
    padding: 24px 30px 30px 30px;
    max-width: 400px;
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 24px 10px 30px 10px;
    `};
`;
const TopCntnt = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 25px;
`;
const LeftBox = styled.div`
    display: flex;
    align-items: center;
`;
const RightBox = styled.div`
`;
const ButButton = styled.button`
    padding: 5px 24px;
    color: white;
    background-color: ${({ theme }) => theme.primary1};
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    border-radius: 4px;
`;
const BottomContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const LeftSide = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    margin-bottom: 5px;
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        width: 130px;
    `};
`;
const LeftP = styled.p`
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.text3};
    margin: 0;
`;
const LeftClmn = styled.p`
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.text3};
    margin: 0;
`;
const Righth4 = styled.h4`
    margin: 0;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.text1};
    text-align: right;
`;
const ForMain = styled.div`
    margin-top: 30px;
`;
const FooterH5 = styled.h5`
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: ${({ theme }) => theme.text1};
`;
const FooterLink = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.text3};
    letter-spacing: 0.32px;
    text-decoration: none;
    transition: all 0.2s ease-out;
    margin-bottom: 8px;
    display: block;
    :hover {
        color: ${({ theme }) => theme.text1};
    }
`;

export default function Header() {
    const [darkMode] = useDarkModeManager();
  return (
    <AsFooter>
        <MuiGridContainer>
            <MuiGridGridLg4>
                <CxPadBox>
                    <TopCntnt>
                        <LeftBox>
                            <img width={'100px'} src={darkMode ? LogoDark : Logo} alt="logo" />
                        </LeftBox>
                        <RightBox>
                                <ButButton>Buy UNIW</ButButton>
                        </RightBox>
                    </TopCntnt>
                    <BottomContent>
                        <div>
                            <LeftSide>
                                <LeftP>Max supply</LeftP>
                                <LeftClmn>:</LeftClmn>
                            </LeftSide>
                            <LeftSide>
                                <LeftP>Circulating supply</LeftP>
                                <LeftClmn>:</LeftClmn>
                            </LeftSide>
                            <LeftSide>
                                <LeftP>Sell Tax</LeftP>
                                <LeftClmn>:</LeftClmn>
                            </LeftSide>
                            <LeftSide>
                                <LeftP>Total Burned</LeftP>
                                <LeftClmn>:</LeftClmn>
                            </LeftSide>
                            <LeftSide>
                                <LeftP>Market Cap</LeftP>
                                <LeftClmn>:</LeftClmn>
                            </LeftSide>
                        </div>
                        <div>
                            <Righth4>50.000.000</Righth4>
                            <Righth4>5.000.000</Righth4>
                            <Righth4>3%</Righth4>
                            <Righth4>0</Righth4>
                            <Righth4>$500.000</Righth4>
                        </div>
                    </BottomContent>
                </CxPadBox>
            </MuiGridGridLg4>
            <MuiGridGridLg2>
                <ForMain>
                    <FooterH5>About</FooterH5>
                    <FooterLink href="https://uniwdex.com">Landing Page</FooterLink>
                    <FooterLink href="https://docs.uniwswap.com">Documents</FooterLink>
                    <FooterLink href="https://forms.gle/uZeiowKVXQSzE7JY7">Bug Bounty</FooterLink>
                    <FooterLink href="https://coinsult.net/projects/uniwswap/" target="_blank">Audit</FooterLink> 
                </ForMain>
            </MuiGridGridLg2>
            <MuiGridGridLg2>
                <ForMain>
                    <FooterH5>Products</FooterH5>
                    <FooterLink href="https://uniwpunks.com" target="_blank">UniWpunks</FooterLink>
                    <FooterLink href="https://uniwgrants.com" target="_blank">UniWgrants</FooterLink>
                    <FooterLink href="https://uniwscan.com/" target="_blank">UniWscan</FooterLink>


                </ForMain>
            </MuiGridGridLg2>
            <MuiGridGridLg2>
                <ForMain>
                    <FooterH5>Social</FooterH5>
                    <FooterLink href="https://twitter.com/uniwswap">Twitter</FooterLink>
                    <FooterLink href="https://t.me/uniwswap">Telegram</FooterLink>
                    <FooterLink href="https://medium.com/uniwswap">Medium</FooterLink>
                </ForMain>
            </MuiGridGridLg2>
            <MuiGridGridLg2>
                <ForMain>
                    <FooterH5>Partnership</FooterH5>
                    <div className="partnerdiv">
                    <a href="https://www.geckoterminal.com/" target="_blank" rel="nofollow">
                    {darkMode ? 
                    <img src="./images/partner/geckoterminal_dark.png" width={"200px"} />
                    :
                    <img src="./images/partner/geckoterminal_light.png" width={"200px"} />
                    }
                    </a> 
                    </div>
                </ForMain>
            </MuiGridGridLg2>
        </MuiGridContainer>
    </AsFooter>
  )
}
