import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { CheckCircle } from 'react-feather';
import styled from 'styled-components';
import { useFetchListCallback } from '../../hooks/useFetchListCallback';
import { TokenList } from '@uniswap/token-lists';

import { useAllLists, useActiveListUrls } from '../../state/lists/hooks';
import { TYPE, IconWrapper } from '../../theme';
import { parseENSAddress } from '../../utils/parseENSAddress';
import uriToHttp from '../../utils/uriToHttp';
import { ButtonPrimary } from '../Button';

import Column, { AutoColumn } from '../Column';
import ListLogo from '../ListLogo';
import Row, { RowFixed, RowBetween } from '../Row';
import { PaddedColumn, SearchInput, Separator } from './styleds';
import useTheme from '../../hooks/useTheme';
import { CurrencyModalView } from './CurrencySearchModal';
import Card from 'components/Card';

const Wrapper = styled(Column)`
  width: 100%;
  height: 100%;
`;

// const ListRow = memo(function ListRow({ listUrl }: { listUrl: string }) {
//   const listsByUrl = useSelector<AppState, AppState['lists']['byUrl']>((state) => state.lists.byUrl);
//   const dispatch = useDispatch<AppDispatch>();
//   const { current: list, pendingUpdate: pending } = listsByUrl[listUrl];

//   const theme = useTheme();
//   const listColor = useListColor(list?.logoURI);
//   const isActive = useIsListActive(listUrl);

//   const [open, toggle] = useToggle(false);
//   const node = useRef<HTMLDivElement>();
//   const [referenceElement, setReferenceElement] = useState<HTMLDivElement>();
//   const [popperElement, setPopperElement] = useState<HTMLDivElement>();

//   const { styles, attributes } = usePopper(referenceElement, popperElement, {
//     placement: 'auto',
//     strategy: 'fixed',
//     modifiers: [{ name: 'offset', options: { offset: [8, 8] } }],
//   });

//   useOnClickOutside(node, open ? toggle : undefined);

//   const handleAcceptListUpdate = useCallback(() => {
//     if (!pending) return;
//     dispatch(acceptListUpdate(listUrl));
//   }, [dispatch, listUrl, pending]);

//   const handleRemoveList = useCallback(() => {
//     if (window.prompt(`Please confirm you would like to remove this list by typing REMOVE`) === `REMOVE`) {
//       dispatch(removeList(listUrl));
//     }
//   }, [dispatch, listUrl]);

//   const handleEnableList = useCallback(() => {
//     dispatch(enableList(listUrl));
//   }, [dispatch, listUrl]);

//   const handleDisableList = useCallback(() => {
//     dispatch(disableList(listUrl));
//   }, [dispatch, listUrl]);

//   if (!list) return null;

//   return (
//     <RowWrapper active={isActive} bgColor={listColor} key={listUrl} id={listUrlRowHTMLId(listUrl)}>
//       {list.logoURI ? (
//         <ListLogo size="40px" style={{ marginRight: '1rem' }} logoURI={list.logoURI} alt={`${list.name} list logo`} />
//       ) : (
//         <div style={{ width: '24px', height: '24px', marginRight: '1rem' }} />
//       )}
//       <Column style={{ flex: '1' }}>
//         <Row>
//           <StyledTitleText active={isActive}>{list.name}</StyledTitleText>
//         </Row>
//         <RowFixed mt="4px">
//           <StyledListUrlText active={isActive} mr="6px">
//             {list.tokens.length} tokens
//           </StyledListUrlText>
//           <StyledMenu ref={node as any}>
//             <ButtonEmpty onClick={toggle} ref={setReferenceElement} padding="0">
//               <Settings stroke={isActive ? theme.bg1 : theme.text1} size={12} />
//             </ButtonEmpty>
//             {open && (
//               <PopoverContainer show={true} ref={setPopperElement as any} style={styles.popper} {...attributes.popper}>
//                 <div>{list && listVersionLabel(list.version)}</div>
//                 <SeparatorDark />
//                 <ExternalLink href={`https://tokenlists.org/token-list?url=${listUrl}`}>View list</ExternalLink>
//                 <UnpaddedLinkStyledButton onClick={handleRemoveList} disabled={Object.keys(listsByUrl).length === 1}>
//                   Remove list
//                 </UnpaddedLinkStyledButton>
//                 {pending && (
//                   <UnpaddedLinkStyledButton onClick={handleAcceptListUpdate}>Update list</UnpaddedLinkStyledButton>
//                 )}
//               </PopoverContainer>
//             )}
//           </StyledMenu>
//         </RowFixed>
//       </Column>
//       <ListToggle
//         isActive={isActive}
//         bgColor={listColor}
//         toggle={() => {
//           isActive ? handleDisableList() : handleEnableList();
//         }}
//       />
//     </RowWrapper>
//   );
// });

export function ManageLists({
  setModalView,
  setImportList,
  setListUrl,
}: {
  setModalView: (view: CurrencyModalView) => void;
  setImportList: (list: TokenList) => void;
  setListUrl: (url: string) => void;
}) {
  const theme = useTheme();

  const [listUrlInput, setListUrlInput] = useState<string>('');

  const lists = useAllLists();

  // sort by active but only if not visible
  const activeListUrls = useActiveListUrls();
  const [activeCopy, setActiveCopy] = useState<string[] | undefined>();
  useEffect(() => {
    if (!activeCopy && activeListUrls) {
      setActiveCopy(activeListUrls);
    }
  }, [activeCopy, activeListUrls]);

  const handleInput = useCallback((e) => {
    setListUrlInput(e.target.value);
  }, []);

  const fetchList = useFetchListCallback();

  const validUrl: boolean = useMemo(() => {
    return uriToHttp(listUrlInput).length > 0 || Boolean(parseENSAddress(listUrlInput));
  }, [listUrlInput]);

  // temporary fetched list for import flow
  const [tempList, setTempList] = useState<TokenList>();
  const [addError, setAddError] = useState<string | undefined>();

  useEffect(() => {
    async function fetchTempList() {
      fetchList(listUrlInput, false)
        .then((list) => setTempList(list))
        .catch(() => setAddError('Error importing list'));
    }
    // if valid url, fetch details for card
    if (validUrl) {
      fetchTempList();
    } else {
      setTempList(undefined);
      listUrlInput !== '' && setAddError('Enter valid list location');
    }

    // reset error
    if (listUrlInput === '') {
      setAddError(undefined);
    }
  }, [fetchList, listUrlInput, validUrl]);

  // check if list is already imported
  const isImported = Object.keys(lists).includes(listUrlInput);

  // set list values and have parent modal switch to import list view
  const handleImport = useCallback(() => {
    if (!tempList) return;
    setImportList(tempList);
    setModalView(CurrencyModalView.importList);
    setListUrl(listUrlInput);
  }, [listUrlInput, setImportList, setListUrl, setModalView, tempList]);

  return (
    <Wrapper>
      <PaddedColumn gap="14px">
        <Row>
          <SearchInput
            type="text"
            id="list-add-input"
            placeholder="https:// or ipfs:// or ENS name"
            value={listUrlInput}
            onChange={handleInput}
          />
        </Row>
        {addError ? (
          <TYPE.error title={addError} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} error>
            {addError}
          </TYPE.error>
        ) : null}
      </PaddedColumn>
      {tempList && (
        <PaddedColumn style={{ paddingTop: 0 }}>
          <Card backgroundColor={theme.bg2} padding="12px 20px">
            <RowBetween>
              <RowFixed>
                {tempList.logoURI && <ListLogo logoURI={tempList.logoURI} size="40px" />}
                <AutoColumn gap="4px" style={{ marginLeft: '20px' }}>
                  <TYPE.body fontWeight={600}>{tempList.name}</TYPE.body>
                  <TYPE.main fontSize={'12px'}>{tempList.tokens.length} tokens</TYPE.main>
                </AutoColumn>
              </RowFixed>
              {isImported ? (
                <RowFixed>
                  <IconWrapper stroke={theme.text2} size="16px" marginRight={'10px'}>
                    <CheckCircle />
                  </IconWrapper>
                  <TYPE.body color={theme.text2}>Loaded</TYPE.body>
                </RowFixed>
              ) : (
                <ButtonPrimary
                  style={{ fontSize: '14px' }}
                  padding="6px 8px"
                  width="fit-content"
                  onClick={handleImport}
                >
                  Import
                </ButtonPrimary>
              )}
            </RowBetween>
          </Card>
        </PaddedColumn>
      )}
      <Separator />
      {/* <ListContainer>
        <AutoColumn gap="md">
          {sortedLists.map((listUrl) => (
            <ListRow key={listUrl} listUrl={listUrl} />
          ))}
        </AutoColumn>
      </ListContainer> */}
    </Wrapper>
  );
}
