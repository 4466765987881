import React from 'react';
import styled from 'styled-components';
import Settings from '../Settings';
import { RowBetween } from '../Row';
import { TYPE } from '../../theme';
import TimerButton from 'components/TimerButton';

const StyledSwapHeader = styled.div`
  padding: 12px 1rem 0px 1rem;
  margin-bottom: 0.4rem;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`;
const RoxBox = styled.div`
  border-bottom: 2px solid #707070;
  padding-bottom: 20px;
`;
const RightPart = styled.div`
  display: flex;
  align-items: center;
`;

export default function SwapHeader() {
  return (
    <StyledSwapHeader>
      <RoxBox>
        <RowBetween>
          <div>
          <TYPE.black fontWeight={700} fontSize={26}>Exchange</TYPE.black>
          <TYPE.black fontWeight={400} fontSize={16} marginTop={10}>The lowest trading fees on all ETHW Chain.<br></br> <b>Only 0.17%</b></TYPE.black>
          </div>
          <RightPart>
            <Settings />
            <TimerButton />
          </RightPart>
          
        </RowBetween>
      </RoxBox>
      
    </StyledSwapHeader>
  );
}
