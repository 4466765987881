import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export default function TopSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="top_sider_main">
        <Slider {...settings}>
          {/* <div className="slider_innr_pernt">
            <img src="/images/slider_img_aj.png" alt="" className="" />
          </div> */}
          <div>
            <a href="https://farm.uniwdex.com" target="_blank" rel="noreferrer">
              <img src="/images/dashboard-banner.jpg" alt="" className="" />
            </a>
          </div>
          <div>
            <a href="#" target="_blank" rel="noreferrer">
              <img src="/images/stable-banner.jpg" alt="" className="" />
            </a>
          </div>
          <div>
            <a href="https://uniwgrant.com" target="_blank" rel="noreferrer">
              <img src="/images/uniwgrant-banner.jpg" alt="" className="" />
            </a>
          </div>
          <div>
            <a href="https://coinsult.net/projects/uniwswap/" target="_blank" rel="noreferrer">
              <img src="/images/audit-banner.jpg" alt="" className="" />
            </a>
          </div>
          <div>
            <a href="https://forms.gle/DMFgDBeaj2rwtnz76" target="_blank" rel="noreferrer">
              <img src="/images/listing-banner.jpg" alt="" className="" />
            </a>
          </div>
        </Slider>
      </div>
    </>
  );
}
