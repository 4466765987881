import React, { useRef } from 'react';
import styled from 'styled-components';
import { useDarkModeManager } from '../../state/user/hooks';
import Logo from '../../assets/svg/timelight.svg';
import LogoDark from '../../assets/svg/timedark.svg';
import { useWalletModalToggle } from 'state/application/hooks';

const StyledMenuButton = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;

  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }

  svg {
    margin-top: 2px;
  }
`;

const StyledMenu = styled.div`
  margin-left: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`;

export default function TimerButton() {
  const node = useRef<HTMLDivElement>();
  const [darkMode] = useDarkModeManager();
  const toggleWalletModal = useWalletModalToggle();
  return (
    // https://bridge.dogechain.dog/bridge
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggleWalletModal}>
        <img width={'25px'} src={darkMode ? Logo : LogoDark} alt="logo" />
      </StyledMenuButton>
    </StyledMenu>
  );
}
